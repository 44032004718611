import React from "react";

import Container from "react-bootstrap/Container";

import Header from "../siteHeader/SiteHeader";
import "./SiteStructure.scss";

const SiteStructure = ({ children }) => {
  return (
    <div className="complete-heigth">
      <Container>
        <Header />
        {children}
      </Container>
    </div>
  );
};

export default SiteStructure;
