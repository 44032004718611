import React from "react";
import Navbar from "react-bootstrap/Navbar";

import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import { Logo } from "../../images/Navbar";

import "./siteHeader.scss";

const SiteHeader = () => {
  return (
    <header style={{ marginBottom: `1.5rem` }}>
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <Navbar.Brand href="/" className="logo">
          <img src={Logo} alt="Blue Sky" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto text-white text-navbar">
            <Nav.Link href="/" className="text-white">
              Home
            </Nav.Link>

            <Nav.Link href="/about" className="text-white">
              Sobre Nosotros
            </Nav.Link>
            <NavDropdown
              className="dropdown-productos"
              title="Productos"
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item
                href="/products/botellas_vino/burdeos/"
                className="text-white"
              >
                Botelllas de Vino
              </NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item
                href="/products/espumantes/"
                className="text-white"
              >
                Espumantes
              </NavDropdown.Item>

              <NavDropdown.Divider />

              <NavDropdown.Item
                href="/products/espirituosas/"
                className="text-white"
              >
                Espirituosas
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                href="/products/area-premium/"
                className="text-white"
              >
                Área premium
              </NavDropdown.Item>
              <NavDropdown.Divider />

              <NavDropdown.Item
                href="/products/cervezas/"
                className="text-white"
              >
                Cervezas
              </NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item
                href="/products/aceites/"
                className="text-white"
              >
                Envases para alimentos
              </NavDropdown.Item>
              <NavDropdown.Divider />

              <NavDropdown.Item href="/products/tapas/" className="text-white">
                Tapas
              </NavDropdown.Item>
              <NavDropdown.Divider />

              <NavDropdown.Item
                href="/products/procesos-especiales/"
                className="text-white"
              >
                Procesos Especiales
              </NavDropdown.Item>
              <NavDropdown.Divider />

              <NavDropdown.Item
                href="/products/barricas/"
                className="text-white"
              >
                Barricas de Orion
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact/" className="text-white">
              Contáctanos
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default SiteHeader;
